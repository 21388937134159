.checklist-block {
  border-left: 5px solid transparent;
  &:hover {
    border-left: 5px solid $cool-gray-500;
  }
}

.draggable-block {
  &:hover {
    background: $gray-300;
    color: $black !important;
  }
}

.checklist-builder-bg {
  background-color: $cool-gray-200;
}