// Custom Color Mode Mixin

@mixin custom-color-mode($name,
  // Grays
  $custom-white: #FFFFFF,
  $custom-gray-100: #F9F9F9,
  $custom-gray-200: #ECECEC,
  $custom-gray-300: #E2E2E2,
  $custom-gray-400: #D4D4D4,
  $custom-gray-500: #B5B5B5,
  $custom-gray-600: #757575,
  $custom-gray-700: #505050,
  $custom-gray-800: #3A3A3A,
  $custom-gray-900: #262626,

  // Color Variants
  $custom-primary: $peacock-600,
  $custom-primary-light: $peacock-100,
  $custom-primary-text: $peacock-600,

  $custom-success: $apple-500,
  $custom-success-light: $apple-100,
  $custom-success-text: $apple-700,

  $custom-info: $blue-500,
  $custom-info-light: $blue-100,
  $custom-info-text: $blue-500,

  $custom-warning: $mango-500,
  $custom-warning-light: $mango-100,
  $custom-warning-text: $mango-700,

  $custom-danger: $carmine-500,
  $custom-danger-light: $carmine-100,
  $custom-danger-text: $carmine-500,

  $custom-light: $white,
  $custom-dark: $gray-900,
) {
  $custom-grays: (
    "100": $custom-gray-100,
    "200": $custom-gray-200,
    "300": $custom-gray-300,
    "400": $custom-gray-400,
    "500": $custom-gray-500,
    "600": $custom-gray-600,
    "700": $custom-gray-700,
    "800": $custom-gray-800,
    "900": $custom-gray-900
  );

  $custom-theme-colors: (
    "primary": $custom-primary,
    "success": $custom-success,
    "info": $custom-info,
    "warning": $custom-warning,
    "danger": $custom-danger,
    "light": $custom-light,
    "dark": $custom-dark
  );

  $custom-text-dark: $custom-gray-900;
  $custom-text-light: $white;
  $custom-text-muted: $custom-gray-600;
  $custom-text-label: $custom-gray-700;
  $custom-text-placeholder: $custom-gray-500;

  $custom-bg-white: $white;
  $custom-bg-light: $custom-gray-100;
  $custom-bg-dark: $custom-gray-800;

  $custom-border: $custom-gray-300;
  $custom-border-light: $custom-gray-200;
  $custom-border-dark: $custom-gray-400;

  @include color-mode($name) {

    @each $color, $value in $custom-grays {
      --#{$prefix}gray-#{$color}: #{$value};
    }

    @each $color, $value in $custom-theme-colors {
      --#{$prefix}#{$color}: #{$value};
      --#{$prefix}#{$color}-rgb: #{to-rgb($value)};
      --#{$prefix}#{$color}-text-emphasis: #{shade-color($value, 60%)};
      --#{$prefix}#{$color}-bg-subtle: #{tint-color($value, 80%)};
      --#{$prefix}#{$color}-border-subtle: #{tint-color($value, 60%)};
    }

    --#{$prefix}secondary-color: #{$custom-gray-600};
    --#{$prefix}secondary-color-rgb: #{to-rgb($custom-gray-600)};

    --#{$prefix}link-color: #{$custom-primary};
    --#{$prefix}link-color-rgb: #{to-rgb($custom-primary)};
    --#{$prefix}link-hover-color: #{shift-color($custom-primary, $link-shade-percentage)};
    --#{$prefix}link-hover-color-rgb: #{to-rgb(shift-color($custom-primary, $link-shade-percentage))};

    --#{$prefix}border-color: #{$custom-gray-400};


    // Text
    .text-primary,
    a {
      color: $custom-primary-text !important;
    }

    .text-primary-icon {
      color: $custom-primary !important;
    }

    .text-success {
      color: $custom-success-text !important;
    }

    .text-info {
      color: $custom-info-text !important;
    }

    .text-warning {
      color: $custom-warning-text !important;
    }

    .text-danger {
      color: $custom-danger-text !important;
    }

    .text-light {
      color: $custom-text-light !important;
    }

    .text-dark {
      color: $custom-text-dark !important;
    }


    // Background color utilities
    .bg-white {
      background-color: $custom-white !important;
      @include text-contrast($custom-white);
    }

    .bg-light {
      background-color: $custom-gray-100 !important;
      @include text-contrast($custom-gray-100);
    }

    .bg-light-hover:hover {
      background-color: $custom-gray-100;
    }

    // Variant backgrounds
    .bg-primary {
      background-color: $custom-primary;
      @include text-contrast($custom-primary);

      &.clickable:hover {
        background-color: shade-color($custom-primary, 15%) !important;
        @include text-contrast(shade-color($custom-primary, 15%));
      }
    }

    .bg-primary-100 {
      background-color: $custom-primary-light;
      @include text-contrast($custom-primary-light);
    }

    // .bg-secondary {
    //   background-color: $custom-secondary;
    //   @include text-contrast($custom-secondary);
    //   &.clickable:hover {
    //     background-color: shade-color($custom-secondary, 15%) !important;
    //     @include text-contrast(shade-color($custom-secondary, 15%));
    //   }
    // }

    .bg-success {
      background-color: $custom-success;
      @include text-contrast($custom-success);

      &.clickable:hover {
        background-color: shade-color($custom-success, 15%) !important;
        @include text-contrast(shade-color($custom-success, 15%));
      }
    }

    .bg-success-100 {
      background-color: $custom-success-light;
      @include text-contrast($custom-success-light);
    }

    .bg-info {
      background-color: $custom-info;
      @include text-contrast($custom-info);

      &.clickable:hover {
        background-color: shade-color($custom-info, 15%) !important;
        @include text-contrast(shade-color($custom-info, 15%));
      }
    }

    .bg-info-100 {
      background-color: $custom-info-light;
      @include text-contrast($custom-info-light);
    }

    .bg-warning {
      background-color: $custom-warning;
      @include text-contrast($custom-warning);

      &.clickable:hover {
        background-color: shade-color($custom-warning, 15%) !important;
        @include text-contrast(shade-color($custom-warning, 15%));
      }
    }

    .bg-warning-100 {
      background-color: $custom-warning-light;
      @include text-contrast($custom-warning-light);
    }

    .bg-danger {
      background-color: $custom-danger;
      @include text-contrast($custom-danger);

      &.clickable:hover {
        background-color: shade-color($custom-danger, 15%) !important;
        @include text-contrast(shade-color($custom-danger, 15%));
      }
    }

    .bg-danger-100 {
      background-color: $custom-danger-light;
      @include text-contrast($custom-danger-light);
    }

    // Subtle variant backgrounds
    .bg-primary-subtle {
      background-color: $custom-primary-light !important;
      @include text-contrast($custom-primary-light);

      &.clickable:hover {
        background-color: $custom-primary !important;
        @include text-contrast($custom-primary);
      }
    }

    .bg-success-subtle {
      background-color: $custom-success-light;
      @include text-contrast($custom-success-light);

      &.clickable:hover {
        background-color: $custom-success-light !important;
        @include text-contrast($custom-success-light);
      }
    }

    .bg-info-subtle {
      background-color: $custom-info-light;
      @include text-contrast($custom-info-light);

      &.clickable:hover {
        background-color: $custom-info-light !important;
        @include text-contrast($custom-info-light);
      }
    }

    .bg-warning-subtle {
      background-color: $custom-warning-light;
      @include text-contrast($custom-warning-light);

      &.clickable:hover {
        background-color: $custom-warning-light !important;
        @include text-contrast($custom-warning-light);
      }
    }

    .bg-danger-subtle {
      background-color: $custom-danger-light;
      @include text-contrast($custom-danger-light);

      &.clickable:hover {
        background-color: $custom-danger-light !important;
        @include text-contrast($custom-danger-light);
      }
    }

    // Gray backgrounds
    .bg-gray-100 {
      background-color: $custom-gray-100;
      @include text-contrast($custom-gray-100);

      &.clickable:hover {
        background-color: $custom-gray-200 !important;
        @include text-contrast($custom-gray-200);
      }
    }

    .bg-gray-200 {
      background-color: $custom-gray-200;
      @include text-contrast($custom-gray-200);

      &.clickable:hover {
        background-color: $custom-gray-300 !important;
        @include text-contrast($custom-gray-300);
      }
    }

    .bg-gray-300 {
      background-color: $custom-gray-300;
      @include text-contrast($custom-gray-300);

      &.clickable:hover {
        background-color: $custom-gray-200 !important;
        @include text-contrast($custom-gray-200);
      }
    }

    .bg-gray-400 {
      background-color: $custom-gray-400;
      @include text-contrast($custom-gray-400);

      &.clickable:hover {
        background-color: $custom-gray-200 !important;
        @include text-contrast($custom-gray-200);
      }
    }

    .bg-gray-500 {
      background-color: $custom-gray-500;
      @include text-contrast($custom-gray-500);

      &.clickable:hover {
        background-color: $custom-gray-200 !important;
        @include text-contrast($custom-gray-200);
      }
    }

    .bg-gray-600 {
      background-color: $custom-gray-600;
      @include text-contrast($custom-gray-600);

      &.clickable:hover {
        background-color: $custom-gray-200 !important;
        @include text-contrast($custom-gray-200);
      }
    }

    .bg-gray-700 {
      background-color: $custom-gray-700;
      @include text-contrast($custom-gray-700);

      &.clickable:hover {
        background-color: $custom-gray-200 !important;
        @include text-contrast($custom-gray-200);
      }
    }

    .bg-gray-800 {
      background-color: $custom-gray-800;
      @include text-contrast($custom-gray-800);

      &.clickable:hover {
        background-color: $custom-gray-200 !important;
        @include text-contrast($custom-gray-200);
      }
    }

    .bg-gray-900 {
      background-color: $custom-gray-900;
      @include text-contrast($custom-gray-900);

      &.clickable:hover {
        background-color: $custom-gray-200 !important;
        @include text-contrast($custom-gray-200);
      }
    }


    // Breadcrumbs
    .breadcrumb {
      color: $custom-gray-600;

      .breadcrumb-item {
        &:not(.active):not(.disabled) {
          cursor: pointer;

          &:hover {
            color: $custom-primary;
          }

          &:active {
            color: $custom-primary;
          }

          &:focus {
            span {
              color: $custom-primary;
              border-color: $custom-primary;
              box-shadow: 0px 0px 3px 0px $custom-primary;
            }
          }
        }

        &.disabled {
          color: $custom-gray-400;
        }

        &::before {
          color: $custom-gray-600;
        }
      }
    }


    // Buttons
    @each $color, $value in $custom-theme-colors {
      .btn-#{$color} {
        @include button-variant-custom($value,
          $value );
      }
    }

    @each $color, $value in $custom-theme-colors {
      .btn-outline-#{$color} {
        @if $color =="light" {
          @include button-outline-variant-custom($value,
            $border: rgba(255, 255, 255, 0.1),
            $active-background: rgba(255, 255, 255, 0.05));
        }

        @else {
          @include button-outline-variant-custom($value,
            $border: rgba(0, 0, 0, 0.1),
            $active-background: rgba(0, 0, 0, 0.05));
        }
      }
    }

    @each $color, $value in $custom-theme-colors {
      .btn-ghost-#{$color} {
        @if $color =="light" {
          @include button-ghost-variant($value,
            $active-background: rgba(255, 255, 255, 0.05));
        }

        @else {
          @include button-ghost-variant($value,
            $active-background: rgba(0, 0, 0, 0.05));
        }
      }
    }

    .btn-outline-primary {
      color: $custom-primary-text;
    }

    .btn-outline-success {
      color: $custom-success-text;
    }

    .btn-outline-info {
      color: $custom-info-text;
    }

    .btn-outline-warning {
      color: $custom-warning-text;
    }

    .btn-outline-danger {
      color: $custom-danger-text;
    }


    .btn-ghost-primary:hover,
    .btn-ghost-success:hover,
    .btn-ghost-info:hover,
    .btn-ghost-warning:hover,
    .btn-ghost-danger:hover {
      color: $custom-text-dark;
    }



    // Forms
    .form-group {
      .form-label {
        color: $custom-gray-700;
      }

      .form-control,
      .form-select {
        background-color: $custom-white;
      }

      &.has-danger {

        .form-control,
        .form-select,
        .form-check-input[type=radio],
        .form-check-input[type=checkbox] {
          border-color: $custom-danger;
        }

        .react-select-control {
          border-color: $custom-danger !important;
        }
      }
    }

    // Radio
    .form-check-input[type=radio] {
      border-color: $custom-gray-500;

      &:hover:not(:disabled, :focus) {
        border-color: $custom-gray-600;
      }

      &:active {
        &:not(:disabled) {
          border-color: $custom-primary;
        }
      }

      &:focus {
        border-color: $custom-primary;
        box-shadow: 0 0 .25rem $input-focus-width rgba($custom-primary, .5);
      }

      &:checked {
        background: radial-gradient($custom-primary 0%, $custom-primary 50%, transparent 55%, transparent);

        &:hover,
        &:active {
          background: radial-gradient(shade-color($custom-primary, $btn-hover-bg-shade-amount) 0%, shade-color($custom-primary, $btn-hover-bg-shade-amount) 50%, transparent 55%, transparent);
        }

        &:disabled {
          background: radial-gradient($custom-gray-600 0%, $custom-gray-600 50%, transparent 55%, transparent);
        }
      }
    }

    // Checkbox
    .form-check-input[type=checkbox] {
      border-color: $custom-gray-500;

      &:hover:not(:disabled, :focus) {
        border-color: $custom-gray-600;
      }

      &:active {
        &:not(:disabled) {
          border-color: $custom-primary;
        }
      }

      &:focus {
        border-color: $custom-primary;
        box-shadow: 0 0 .25rem $input-focus-width rgba($custom-primary, .5);
      }

      &:checked,
      &:indeterminate {
        background-color: $custom-primary;

        &:hover,
        &:active {
          background-color: shade-color($custom-primary, $btn-hover-bg-shade-amount);
        }

        &:disabled {
          background-color: $custom-gray-600;
        }
      }
    }

    .form-control,
    .form-select {
      border: $input-border-width solid $custom-gray-400;

      &:hover {
        border-color: $custom-gray-500;
      }

      &:active {
        border-color: $custom-primary;
      }

      &:focus {
        border-color: $custom-primary;
        box-shadow: 0 0 .25rem $input-focus-width rgba($custom-primary, .5);
      }

      &:disabled {
        background-color: $custom-gray-100;
        border-color: $custom-gray-400;
      }
    }

    // React Select
    .react-select-control {
      border: $input-border-width solid $custom-gray-400 !important;

      &:hover {
        border-color: $custom-gray-500 !important;
      }

      &:active {
        border-color: $custom-primary !important;
      }

      &:focus {
        border-color: $custom-primary !important;
        box-shadow: 0 0 .25rem $input-focus-width rgba($custom-primary, .5) !important;
      }

      &:disabled {
        background-color: $custom-gray-100 !important;
        border-color: $custom-gray-400 !important;
      }
    }

    .react-select-multivalue {
      background-color: tint-color($custom-primary, 80%) !important;
    }

    .react-select-multivalue-remove:hover {
      background-color: tint-color($custom-danger, 80%) !important;
      color: $custom-danger !important;
    }


    // React Switch
    .react-switch {
      .react-switch-bg {
        border: 1px solid white;
      }

      &.switch-checked {
        .react-switch-bg {
          background-color: $custom-primary !important;
        }

        &:hover:not(.switch-disabled),
        &:active:not(.switch-disabled) {
          .react-switch-bg {
            background-color: shade-color($custom-primary, $btn-hover-bg-shade-amount) !important;
          }
        }
      }

      &:not(.switch-checked) {
        .react-switch-bg {
          background-color: $custom-gray-500 !important;
        }

        &:hover:not(.switch-disabled),
        &:active:not(.switch-disabled) {
          .react-switch-bg {
            background-color: $custom-gray-600 !important;
          }
        }
      }

      &:focus-within {
        .react-switch-bg {
          border: 1px solid white;
          outline: 1px solid $custom-primary;
          // outline-offset: -2px;
          box-shadow: 0 0 .25rem .1rem rgba($custom-primary, .5);
        }
      }
    }

  // Dropdown
  .dropdown-menu {
    border: $custom-border-light;
  }

  // Toast
  // primary
  .Toastify__progress-bar--primary {
    background: $custom-primary;
  }

    .Toastify__toast--primary {
      svg {
        fill: $custom-primary;
      }
    }

    // success
    .Toastify__progress-bar--success {
      background: $custom-success;
    }

    .Toastify__toast--success {
      svg {
        fill: $custom-success;
      }
    }

    // info
    .Toastify__progress-bar--info {
      background: $custom-info;
    }

    .Toastify__toast--info {
      svg {
        fill: $custom-info;
      }
    }

    // warning
    .Toastify__progress-bar--warning {
      background: $custom-warning;
    }

    .Toastify__toast--warning {
      svg {
        fill: $custom-warning;
      }
    }

    // danger
    .Toastify__progress-bar--danger {
      background: $custom-danger;
    }

    .Toastify__toast--danger {
      svg {
        fill: $custom-danger;
      }
    }

    // Nav
    .nav-tabs,
    .meter-nav {
      border: none;

      .nav-item {
        .nav-link {
          border-bottom-color: $custom-gray-200;
          color: $custom-text-dark !important;
          border-bottom-width: 2px !important;

          &.meter-link {
            color: $custom-gray-700 !important;
          }

          &.active {
            color: $custom-primary !important;
            border-bottom-color: $custom-primary !important;
            border-bottom-width: 2px !important;
          }

          &.disabled {
            color: $custom-gray-500;
          }

          &:hover,
          &:active {
            background-color: $custom-gray-200;
          }
        }
      }
    }

    .sidenav {
      background-color: $custom-gray-100 !important;
      @include text-contrast($custom-gray-100);
    }


    // // Previews
    // .document-preview-header {
    //   background: $custom-gray-800;
    // }

    /* Set the background color of the scrollbar track to transparent */
    .document-preview-modal-body::-webkit-scrollbar-track,
    .document-preview-leftnav::-webkit-scrollbar-track {
      background-color: #EDEDED;
    }

    /* Set the color and style of the scrollbar thumb */
    .document-preview-modal-body::-webkit-scrollbar-thumb,
    .document-preview-leftnav::-webkit-scrollbar-thumb {
      background-color: $custom-gray-600;
      border-color: #EDEDED;
      border-radius: 25px;
    }

    /* Set the color of the scrollbar thumb on hover */
    .document-preview-modal-body::-webkit-scrollbar-thumb:hover,
    .document-preview-leftnav::-webkit-scrollbar-thumb:hover {
      background-color: $custom-gray-700;
    }

    .modal-preview-left-navbar {
      background: $custom-gray-800;
    }


    // Tables
    .table-wrapper {
      background-color: $custom-white !important;
      @include text-contrast($custom-white);
    }

    .table {
      border-color: $custom-gray-400;

      .thead,
      .tbody,
      .tr {

        .th:not(:last-child) .th-divider {
          background-color: $custom-gray-400;
        }

        // .edit-background-color {
        //   background-color: #D3DDFC;
        // }

        .resize-handle {
          &:active {
            border-color: $custom-primary;
          }
        }

        .card-hover {
          color: $custom-gray-700;
        }

        .card-hover:hover>*:not(.card-header) {
          background-color: $custom-gray-100;
        }

        .card-hover>.card-header {
          background-color: tint-color($custom-primary, 80%) !important;
        }

        .card-hover:hover>.card-header {
          background-color: tint-color($custom-primary, 70%) !important;
        }
      }

      .tr>.td {
        background-color: $custom-white;
        @include text-contrast($custom-white);

        .skeleton {
          background-color: $custom-gray-200;
        }
      }

      .tbody .tr:hover>*:not(.edit-background-color):not(.active-bg) {
        background-color: $custom-gray-100;
      }

      // .tbody .tr .active-bg:not(.edit-background-color) {
      //   background-color: rgb(229, 243, 248);
      // }

      .th {
        background-color: $custom-white;
        @include text-contrast($custom-white);

        h6 {
          color: $custom-gray-700;
        }
      }

      .td {
        border-color: $custom-gray-400;
      }
    }

    .star-checkbox input[type='checkbox']:checked+label:before {
      color: $custom-warning;
    }

    @keyframes checkbox {
      from {
        font-weight: 100
      }

      to {
        font-weight: 600;
        color: $custom-warning
      }
    }

    .list-item-box {
      border-color: $custom-gray-200;
    }


    // Misc
    .svg-stroke {
      stroke: $custom-gray-700;

      &.selected {
        stroke: $custom-primary;
      }
    }

    .btn-scroll-to-top {
      background-color: $custom-primary;
    }

    // Misc
    // Dashboard
    .dashboard-banner {
      height: 130px;
      background:
        linear-gradient(90deg, $custom-primary 0%, rgba(1, 101, 93, 0.16) 100%),
        url('../assets/images/dashboard.png');
      background-size: cover;
      background-position: center;
      @include text-contrast($custom-primary);
    }
  }
}