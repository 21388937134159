/* Default styles for small to large screens */
.page {
  box-shadow: 0 0 5px rgba(0, 0, 0, .4);;
  animation: pageIn 1s ease;
  // transition: all 1s ease, width 0.2s ease;
}

/* Common styles for all media queries */
.page {
  margin: 10px;
  display: block;
  max-width: 100%; /* Ensures it doesn't overflow */
  max-height: 100%; /* Prevents pages from being too large */
  height: auto;
  width: auto;
}

/* Media query adjustments */
@media only screen and (max-width: 576px) {
  .page {
    // width: 85%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .page {
    // width: 90%;
  }
}

/* Common styles for all media queries */
.pageMini {
  margin: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  animation: pageIn 1s ease;
  // transition: all 1s ease, width 0.2s ease;
  // width: 75%;
  width: 75px
}

/* Media query for extra-small screens (xs) */
// @media only screen and (max-width: 576px) {
//   .pageMini {
//     width: 85%;
//   }
// }

// /* Media queries for small and medium screens (sm and md) */
// @media only screen and (min-width: 576px) and (max-width: 991px) {
//   .pageMini {
//     width: 90%;
//   }
// }

#zoom-percent {
  display: inline-block;
}

#zoom-percent::after {
  content: "%";
}

.document-preview-modal {
  background-color: #AEB5BC;
  background-blend-mode: multiply;
  border: none;
}

.document-preview-header {
  background: $white;
  background-blend-mode: multiply;
}

.document-preview-modal-size {
  max-width: 97.5%;
  overflow-y: initial !important;
}

.document-preview-modal-body {
  max-height: 87.5vh;
  // overflow-y: auto;
}

/* Set the width and height of the scrollbar */
.document-preview-modal-body::-webkit-scrollbar,
.document-preview-leftnav::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

/* Set the background color of the scrollbar track to transparent */
.document-preview-modal-body::-webkit-scrollbar-track,
.document-preview-leftnav::-webkit-scrollbar-track {
  background-color: #EDEDED;
}

/* Set the color and style of the scrollbar thumb */
.document-preview-modal-body::-webkit-scrollbar-thumb,
.document-preview-leftnav::-webkit-scrollbar-thumb {
  background-color: $gray-600;
  border: 1px solid #EDEDED;
  border-radius: 25px;
}

/* Set the color of the scrollbar thumb on hover */
.document-preview-modal-body::-webkit-scrollbar-thumb:hover,
.document-preview-leftnav::-webkit-scrollbar-thumb:hover {
  background-color: $gray-700;
}

.modal-preview-left-navbar {
  height: 100%;
  width: 150px;
  z-index: 1;
  top: 0;
  left: 0px;
  // background: $gray-800;
  background: rgba(255, 255, 255, 0.30);
  overflow-x: hidden;
  transition: 0.5s;
}

/* Media query for screens smaller than 576px (typical small screens) */
@media (max-width: 575.98px) {
  .modal-preview-left-navbar {
    width: 50px;
  }
}

/* Media query for screens between 576px and 991px (typical medium screens) */
@media (min-width: 576px) and (max-width: 991.98px) {
  .modal-preview-left-navbar {
    width: 100px;
    /* Adjust the width for medium screens */
  }
}

.modal-body .main-content {
  flex-grow: 1;
  padding-left: 250px;
}

.mini-page-preview {
  height: '100%';
  transition: 'none';
  overflow-y: 'auto';
  overflow-x: 'hidden';
}

#left-nav-pages {
  background-color: rgba(255, 255, 255, 0.30);
}